import React, { useEffect, useRef, useState } from "react";
import Swiper from "swiper";
import Homes from "./Homes";
import { Link } from "react-router-dom";
import "swiper/swiper-bundle.css";
import Nav from "../component/Nav";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import MobileNav from "./MobileNav";
import Son from "../Assets/p1.jpg";


const firebaseConfig = {
  // Your Firebase configuration
  apiKey: "AIzaSyCRSVN6rIpkPQKLz77NFcES36Fpe665T9k",
  authDomain: "nextberries-cb5b9.firebaseapp.com",
  projectId: "nextberries-cb5b9",
  storageBucket: "nextberries-cb5b9.appspot.com",
  messagingSenderId: "44749615279",
  appId: "1:44749615279:web:0b3e110f248c8e8b3ae45a",
  measurementId: "G-9QZ27VRVHL",
  // ...
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Shop = () => {
  const trandingSliderRef = useRef(null);
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    const fetchSliderData = async () => {
      const db = firebase.firestore();
      const collection = db.collection("Products");

      try {
        const snapshot = await collection.get();
        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setSliderData(data);

        const trandingSlider = new Swiper(trandingSliderRef.current, {
          // Initialize Swiper instance
        //   direction: "horizontal",
        // effect: "contain",
        // grabCursor: true,
        // centeredSlides: true,
        // loop: true,
        // slidesPerView: "auto",
        // coverflowEffect: {
        //   rotate: 0,
        //   stretch: 0,
        //   depth: 100,
        //   modifier: 2.5,
        // },
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // autoplay: {
        //   delay: 500,
        //   disableOnInteraction: false,
        // },
          // ...
        });

        const timer = setInterval(() => {
          if (trandingSlider && trandingSliderRef.current) {
            trandingSlider.slideNext();
          }
        }, 2000); // Adjust the interval value (in milliseconds) for auto sliding

        return () => {
          clearInterval(timer);
          if (trandingSlider) {
            trandingSlider.destroy();
          }
        };
      } catch (error) {
        console.log("Error fetching slider data:", error);
      }
    };

    fetchSliderData();
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsIconClosed(!isIconClosed);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isIconClosed, setIsIconClosed] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  return (
    <div className="bg-[#fff] items-center justify-center h-screen w-screen">
      <div 
      // className=" bg-no-repeat bg-cover "
      >
       
        <div 
            // style={{backgroundImage: `url(${Son})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
        className="bg-[#fff] h-[100%]"
        >
          <Homes />
        </div>
      </div>

      <Nav/>
      <MobileNav  title={"me"} />
    </div>
  );
};

export default Shop;
