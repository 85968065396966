import React, { useState } from "react";
import { useCart } from "react-use-cart";
import { MdOutlineShoppingCart } from "react-icons/md";
import PaystackForm from "./PaystackForm";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Nav from "../component/Nav";
import Homes from "./Homes";
import MobileNav from "./MobileNav";
import { Link, useNavigate } from "react-router-dom";
import Son from "../Assets/seb.jpg";


const firebaseConfig = {
  // Your Firebase config
  apiKey: "AIzaSyCRSVN6rIpkPQKLz77NFcES36Fpe665T9k",
  authDomain: "nextberries-cb5b9.firebaseapp.com",
  projectId: "nextberries-cb5b9",
  storageBucket: "nextberries-cb5b9.appspot.com",
  messagingSenderId: "44749615279",
  appId: "1:44749615279:web:0b3e110f248c8e8b3ae45a",
  measurementId: "G-9QZ27VRVHL",
};

firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();

const Cart = () => {
  const {
    isEmpty,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();

  const [productQuantities, setProductQuantities] = useState({});

  const handlePaymentSuccess = (response, userData) => {
    console.log("Payment successful:", response);

    const payeeName = userData.name;
    const payeeLocation = userData.location;
    const payeeEmail = userData.email;
    const payeePostalCode = userData.postalCode;
    const payeePhoneNumber = userData.phoneNumber;

    const productsInCart = items.map((item) => ({
      id: item.id,
      name: item.title,
      quantity: productQuantities[item.id] || item.quantity,
    }));

    const data = {
      amount: cartTotal,
      createdAt: new Date(),
      productPaidFor: items.map((item) => item.title).join(", "),
      product_image: items.map((item) => item.product_image).join(", "),
      quantity: items.reduce((total, item) => total + item.quantity, 0),
      payeeName,
      payeeLocation,
      payeeEmail,
      payeePostalCode,
      payeePhoneNumber,
      productsInCart,
      status: 'Successful'

    };

    firestore
      .collection("Users")
      .doc(firebase.auth().currentUser.uid)
      .collection("Payments")
      .add(data)
      .then((docRef) => {
        console.log("Payment data saved with ID:", docRef.id);
      })
      .catch((error) => {
        console.error("Error saving payment data:", error);
      });

      // navigate()

      
    };
    
    // const navigate = <Link to={'/order'} />

  const handlePaymentClose = () => {
    console.log("Payment cancelled");
  };


  const handleOrderAttempts = (response, userData) => {
    const payeeName = userData.name;
    const payeeLocation = userData.location;
    const payeeEmail = userData.email;
    const payeePostalCode = userData.postalCode;
    const payeePhoneNumber = userData.phoneNumber;

    const productsInCart = items.map((item) => ({
      id: item.id,
      name: item.title,
      quantity: productQuantities[item.id] || item.quantity,
    }));

    const data = {
      amount: cartTotal,
      createdAt: new Date(),
      productPaidFor: items.map((item) => item.title).join(", "),
      product_image: items.map((item) => item.product_image).join(", "),
      quantity: items.reduce((total, item) => total + item.quantity, 0),
      payeeName,
      payeeLocation,
      payeeEmail,
      payeePostalCode,
      payeePhoneNumber,
      productsInCart,
      status: 'Attempt'
    };
    firestore
    .collection("Users")
    .doc(firebase.auth().currentUser.uid)
    .collection("OrderAttempt")
    .add(data)
    .then((docRef) => {
      console.log("Payment data saved with ID:", docRef.id);
    })
    .catch((error) => {
      console.error("Error saving payment data:", error);
    }); 
   };


  return (
    <div 
        // style={{backgroundImage: `url(${Son})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    className="w-screen grid justify-center text-center pt-10 lg:pt-16 bg-[#3e3435]">
    {/* className="w-screen grid justify-center text-center pt-10 lg:pt-16 bg-[#656564]"> */}
      <h1 className="text-white text-6xl text-center m-5">
        Cart
      </h1>
      <h1 className="text-light">{isEmpty ? "Your Cart is Empty" : ""}</h1>
      <div className="justify-content-center">
        <div
          responsive="sm"
          striped
          bordered
          hover
          className="mb-0 rounded-md p-2"
        >
          <tbody className="grid justify-center">
            {items.map((item, index) => {
              return (
                <tr
                  key={index}
                  className="md:flex p-4 px-10 w-80 md:w-fit bg-white rounded-md mb-14 justify-center items-center text-center grid grid-cols-1"
                >
                  <td className=" grid justify-center">
                    <div
                      className="rounded-lg ml- md:flex flex-col text-center justify-center mb-0 drop-shadow-2xl shadow-2xl"
                      style={{
                        background: "white",
                        height: "10rem",
                        overflow: "hidden",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                        <img
                          src={item.product_image}
                          className="w-40 p-10 ml-3 md:ml-0 text-center shadow-2xl drop-shadow-2xl hover:scale-110 duration-500"
                          alt={item.title}
                        />
                    </div>
                  </td>
                  <td>
                    <h6 className="md:pl-10 text-center w-80 justify-center grid mt-8 pr-10 font-bold text-gray-800">
                      {item.title}
                  <td className="text-green-500 -10">$ {item.price}</td>
                    </h6>
                  </td>

                  <td className="">
                    <div className="flex text-center justify-center">
                    <button
                      onClick={() =>
                        updateItemQuantity(item.id, item.quantity - 1)
                      }
                      className="text-3xl m-4 p-2 w-14 bg-white rounded-full drop-shadow-2xl shadow-2xl hover:bg-green-600 hover:text-white duration-500  text-green-600"
                      >
                      -
                    </button>
                      <button className="text-gray-600 drop-shadow-2xl p-2  shadow-2xl w-14 duration-500 m-4 text-2xl rounded-full bg-white ">{item.quantity}</button>
                    <button
                      onClick={() =>
                        updateItemQuantity(item.id, item.quantity + 1)
                      }
                      className="text-3xl m-4 p-2 w-14 bg-white rounded-full drop-shadow-2xl shadow-2xl hover:bg-green-600 hover:text-white duration-500  text-green-600"
                      >
                      +
                    </button>{" "}
                      </div>
                      {/* <div className="flex items-center justify-center">
                    <button
                      onClick={() =>
                        updateItemQuantity(item.id, item.quantity - 1)
                      }
                      className="text-4xl p-5 m-4 bg-white rounded-full duration-500 drop-shadow-2xl shadow-2xl hover:bg-red-600 hover:text-white w-20 text-red-600"
                    >
                      -
                    </button>
                    <p className="w-20 bg-white shadow-2xl drop-shadow-2xl rounded-full p-5">
                      {item.quantity}
                      </p>
                    <button
                      onClick={() =>
                        updateItemQuantity(item.id, item.quantity + 1)
                      }
                      className="text-4xl m-4 p-5 w-20 bg-white rounded-full drop-shadow-2xl shadow-2xl hover:bg-green-600 hover:text-white duration-500  text-green-600"
                    >
                      +
                    </button>
                  </div> */}
                    <br className="md:hidden" />
                    <button
                      variant="danger"
                      onClick={() => removeItem(item.id)}
                      className="ms-2 text-red-600 font-bold bg-white rounded-lg hover:text-white hover:bg-red-600 duration-700 p-2"
                    >
                      Remove Item
                    </button>
                  </td>
                  <td className="text-yellow-500 items-baseline font-extralight md:ml-5 mt-5 pl-5">
                    Free Shipping
                    {/* {item.shipping_fee} */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </div>
        {!isEmpty && (
          <div
            // style={{ }}
            className="bg-light-black text-light mb-0"
          >
            <h2 className="py-2 text-2xl text-gray-50 ml-8 text-center font-bold">
              Total Price: $ {cartTotal}
            </h2>
            <div className="p-2 flex justify-center" md={4}>
              {/* <button
                variant="danger"
                className="m-2 text-red-600 font-bold text-center border-2 border-red-500 p-1 hover:bg-red-500 hover:text-white duration-500 rounded-lg"
                onClick={() => emptyCart()}
              >
                <MdOutlineShoppingCart size="1.7rem" className="ml-8" />
                Clear Cart
              </button> */}
                 <div
                variant="danger"
                className="m-2 md:w-48 flex font-bold cursor-pointer text-center justify-center items-center hover:text-red-500 hover:bg-white drop-shadow-2xl shadow-2xl border-red-500 py-7 px-2 md:py-10 bg-red-500 text-white duration-500 rounded-lg"
                onClick={() => emptyCart()}
              >
                <MdOutlineShoppingCart size="1.7rem" className="mr-2" />  Clear Cart
              </div>

              <button
                variant="success"
              >
                <PaystackForm

                  amount={cartTotal} // Use the cart total as the payment amount
                  email={firebase?.auth()?.currentUser?.email} // Replace with the customer's email
                  onSuccess={handlePaymentSuccess}
                  onClose={handlePaymentClose}
                  handleOrderAttempts={handleOrderAttempts}
                />
              </button>
            </div>
          </div>
        )}

<div className=" mt-[30%]  w-full justify-center items-center">
            <h1 className=" text-center text-xl md:font-bold font-bold mb-6 mt-2 text-white">
              &copy; Nextberries 2023. All rights reserved <Link className='hover:text-blue-500' to={'/policy'}>Privacy Policy</Link>.
            </h1>
          </div>
      </div>
      <Nav />
      <MobileNav />
         {/* <div className="bg-gray-900">
          <Homes />
        </div> */}
    </div>
  );
};

export default Cart;
