import React, { useEffect, useRef, useState, Suspense, useTransition  } from "react";
import { useControls } from 'leva'
import { Canvas, useThree } from '@react-three/fiber'
import { AccumulativeShadows, RandomizedLight, Center, Environment, OrbitControls, Stage } from '@react-three/drei'
// import TOP from './TOP'
import CanvasLoader from '../Loader'
import { useGLTF, useTexture } from "@react-three/drei";
import { MeshStandardMaterial, Vector3 } from "three";
import * as THREE from 'three';


export default function EnvApp(props) {
  function TOP() {
    const [currentModelIndex, setCurrentModelIndex] = useState(2);
    const [Xy, setXy] = useState(true);
  
    const models = [
      './1/gltf/1.gltf',
      './2/gltf/2.gltf'
    ];

    useEffect(() => {
      if(props.Show1 === !props.Show1){
        nextModel()
      }
    }, [])
  
    const nextModel = () => {
      setCurrentModelIndex((prevIndex) => (prevIndex + 1) % models.length);
    };
  
    const Model = () => {
      const gltf1 = useGLTF("./1/gltf/1.gltf");
      const gltf2 = useGLTF("./2/gltf/2.gltf");
      // const gltf3 = useGLTF("./3/gltf/3.gltf");
      const gltf4 = useGLTF("./4/gltf/4.gltf");
      const gltf5 = useGLTF("./5/gltf/5.gltf");
      const gltf6 = useGLTF("./6/gltf/6.gltf");
      // const gltf = useGLTF(models[currentModelIndex]);
      return (
        <>
        {
          props.Show1 === 1 ?
          <primitive object={gltf1.scene} scale={2.5} />
          : props.Show1 === 2 ?
          <primitive object={gltf2.scene} scale={2.5} />
          // : props.Show1 === 3 ?
          // <primitive object={gltf3.scene} scale={2.5} />
          : props.Show1 === 3 ?
          <primitive object={gltf4.scene} scale={2.5} />
          : props.Show1 === 4 ?
          <primitive object={gltf5.scene} scale={2.5} />
          : props.Show1 === 5 &&
          <primitive object={gltf6.scene} scale={2.5} />
        }
        </>
      )
    };
  
    return (
      <group  dispose={null} position={[0, -2.5, 0]}>
        <Model />
        {/* <button onClick={nextModel}>Next</button> */}
      </group>
    );
  }
  
  return (
    <Canvas shadows camera={{ position: [0, 0, 4.5], fov: 50 }} >
                <Suspense fallback={<CanvasLoader />}>

      {/* <group position={[0, -0.65, 0]}> */}
        {/* <Sphere /> */}
        <Stage intensity={10} shadow-bias={-0.0001} castShadow={true}>
          
        <TOP />
        </Stage>

        <AccumulativeShadows temporal frames={200} color="purple" colorBlend={0.5} opacity={1} scale={10} alphaTest={0.85}>
          <RandomizedLight amount={8} radius={5} ambient={0.5} position={[5, 3, 2]} bias={0.001} />
        </AccumulativeShadows>
      {/* </group> */}
      <Env />
      <OrbitControls autoRotate autoRotateSpeed={4} enablePan={false} enableZoom={true} />
      {/* <OrbitControls autoRotate autoRotateSpeed={4} enablePan={false} enableZoom={true} minPolarAngle={Math.PI / 2.1} maxPolarAngle={Math.PI / 2.1} /> */}
   </Suspense>
    </Canvas>
  )
}

// function Sphere() {
//   const { roughness } = useControls({ roughness: { value: 1, min: 0, max: 1 } })
//   return (
//     <Center top>
//       <mesh castShadow>
//         <sphereGeometry args={[0.75, 64, 64]} />
//         <meshStandardMaterial metalness={1} roughness={roughness} />
//       </mesh>
//     </Center>
//   )
// }

function Env() {
  const [preset, setPreset] = useState('sunset')
  // You can use the "inTransition" boolean to react to the loading in-between state,
  // For instance by showing a message
  const [inTransition, startTransition] = useTransition()
  const { blur } = useControls({
    blur: { value: 0.65, min: 0, max: 1 },
    preset: {
      value: preset,
      options: ['sunset', 'dawn', 'night', 'warehouse', 'forest', 'apartment', 'studio', 'city', 'park', 'lobby'],
      // If onChange is present the value will not be reactive, see https://github.com/pmndrs/leva/blob/main/docs/advanced/controlled-inputs.md#onchange
      // Instead we transition the preset value, which will prevents the suspense bound from triggering its fallback
      // That way we can hang onto the current environment until the new one has finished loading ...
      onChange: (value) => startTransition(() => setPreset(value))
    }
  })
  return <Environment preset={preset} background blur={blur} />
}


