import React, { useState, useEffect } from "react";
import Explore from "../component/Explore";
import ShopNow from "../component/Shopnow";
import Welcome from "../component/Welcome";
import Nav from "../component/Nav";
import MobileNav from "./MobileNav";
import Footer2 from "./Footer2";
import ParallaxBlurImage from "./Parallax";
import Shop from "./Shop";
import { Link } from "react-router-dom";
// import ParallaxBlurImage from './ParallaxBlurImage';

const Home = () => {
  const slides = [<Welcome />, <Explore />, <ShopNow />];
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpin, setIsOpin] = useState(false);
  const [isNestedOpen, setIsNestedOpen] = useState(false);
  const [isIconClosed, setIsIconClosed] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsIconClosed(!isIconClosed);
  };

  const toggleNestedDropdown = () => {
    setIsNestedOpen(!isNestedOpen);
  };

  const toggleNestedDropdown2 = () => {
    setIsOpin(!isOpin);
  };

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // Change slide every 5 seconds

    return () => {
      clearInterval(slideInterval);
    };
  }, []);

  return (
    <>
      {/* <div className="relative h-screen overflow-hidden">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute top-0 left-0 w-full h-full transition-opacity duration-500 ${
              index === currentSlide ? "opacity-100" : "opacity-0"
            }`}
          >
            {slide}
          </div>
        ))}
      </div> */}


      <Explore />

      {/* <div className="w-[100%]">


<div className=" pt-0">
<div className="sm:hidden md:pt-40"></div>
<div className="lg:pt-0"></div>
<div className="sm:hidden pt-0"></div>




<div className="md:pr-20 md:pl-20">

   
<section class="bg-white w-[90%]">
    <div class="gap-0 items-center py-8 px-4 mx-auto lg:grid lg:grid-cols-2  lg:py-16 lg:px-6">

        <div class=" relative mb-64">
            <div className='hidden md:flex'>
                <img class="absolute top-0 left-28 object-cover w-64 h-80" src={"https://plus.unsplash.com/premium_photo-1683147612215-a21369ead458?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="office content 2" />
            </div>
        </div>
        <div class=" sm:mt-10">
            <h2 class="mb-4 text-2xl tracking-tight font-extrabold text-gray-900">Know more about us</h2>
            <p class="mb-4 text-gray-500 text-sm">Brotherhood of the Cross and Star is a global divine institution. It teaches and practices Universal love, peace, unity, truth and righteousness in the Holy Spirit. The World Headquarters (WHQ) of Brotherhood of the Cross and Star situates at No 34 Ambo Street Calabar, Nigeria West Africa...</p>
            <div>
                <Link
                   onClick={() => {
                    window.scrollTo(0, 0);
                  }}  
                 to="/About" className='text-xs bg-red-600 text-white p-3 rounded-md'>Learn more</Link>
            </div>
        </div>
    </div>
</section>

</div>
</div>
</div> */}


      <Nav title={"me"} isOpen={isOpen} toggleDropdown={toggleDropdown} />
      <MobileNav />
      {/* <Footer2 /> */}
    </>
  );
};

export default Home;
