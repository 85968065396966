import React from "react";
// import Video2 from "../Assets/second.MP4";
// import Video2 from "../Assets/v2.mp4";
import Video2 from "../Assets/p1.mp4";
// import V1 from "../Assets/left3.mp4";
import V1 from "../Assets/R12.png";
import V2 from "../Assets/R11.jpg";
// import V2 from "../Assets/right.mp4";
import Video1 from "../Assets/f1.mp4";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff'
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('body');


const Explore = () => {
   let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(!modalIsOpen);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="w-screen h-[100%] flex justify-center items-center">
        {/* <video
          src={Video2}
          autoPlay
          loop
          muted
          className="w-full h-full object-cover -top-2 absolute"
        /> */}
<div
  // style={{width: '100%'}}
  className="hidden lg:flex w-[100%] h-[100%] justify-center items-center object-cover absolute"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
    position: absolute;
    width: 100%;
    top: 0px;
    bottom: 0px;
    height: 100%;
    left: 0px;
    right: 0px;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${Video1} type="video/mp4" />
    Your browser does not support the video tag.
  </video>`
}}
/> 
   
   {/* Mobile */}
<div
  // style={{width: '100%'}}
  className="lg:hidden w-[100%]  h-[130%] flex justify-center items-center object-cover absolute"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${Video2} type="video/mp4" />
    Your browser does not support the video tag.
  </video>`
}}
/>    


{ 
modalIsOpen ?

        <div
        onClick={openModal}
         className="text-black bg-[rgba(255,255,255,0.3)] cursor-pointer w-screen h-screen absolute z-50 m-10  items-center justify-center flex">
 
  <Link to={'/Customization'}>
        <div>
          <div className="lg:w-72 cursor-pointer lg:p-10 p-2 w-44">
          <h1 className="text-[#fff] text-2xl text-center m-5">
          Latest Collection
      </h1>
            <img src={V1} autoPlay controls={false} muted loop  alt="logo" className=" rounded-2xl h-44 w-44 self-center" />
          </div>
        </div>
        </Link>
        <Link to={'/shop'}>
        <div>
          <div className="lg:w-72 lg:p-10 p-2  w-44">
          <h1 className="text-[#fff] text-2xl text-center m-5">
       Shop Collection
      </h1>
            <img src={V2} autoPlay controls={false} muted loop alt="logo" className="rounded-2xl h-44 w-44 self-center" />
          </div>
        </div>
        </Link>
      
        </div>
        : null
}

        {/* <div className="relative border-white border-2 w-56 h-16 cursor-pointer mt-[40%]">
          <h1 className="text-white text-3xl text-center mt-3">
            <a href="/shop">EXPLORE</a>
          </h1>
        </div> */}
            <div className=" z-50 items-center text-center text-white absolute bottom-20">
              <div>
                NEXTBERRIES EZUMEZU COLLECTION
              </div>
              <div className="text-xs">
               Nigeria, New York, Paris, London
              </div>
            <div className="flex">
          <Link
            // onClick={openModal}
            // to={'/shop'}
            to={'/Customization'}
            className="block animate-pulse px-7 py-4 text-sm text-center text-white"
          >

            Discover More
            <div className="h-[2px] w-[120px] bg-white" />
          </Link>
          <Link
            // to={'/Customization'}
            // onClick={openModal}
            to={'/shop'}
            className="block animate-pulse px-7 py-4 text-sm text-center text-white "
          >

            Shop Now
            <div className="h-[2px] w-[80px] bg-white" />
          </Link>
        </div>
        </div>
      </div>
    </>
  );
};

export default Explore;
