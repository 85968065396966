import React from 'react'
import './index.css'
import Customization from './Customization'
import { App as Canvas } from './Canvas'
import { Overlay } from './Overlay'

function CusCall() {
  return (
    <>
    {/* <audio   autoPlay loop src='https://firebasestorage.googleapis.com/v0/b/universe-ff7ed.appspot.com/o/Private%2FAurora_-_Runaway_Niggaloaded.com.ng.mp3?alt=media&token=bd784e42-23d2-4e4c-91ac-f86d7fb29569&_gl=1*z7ao2z*_ga*MjAyMDYwMzM5NC4xNjk2MTc1MTA3*_ga_CW55HF8NVT*MTY5NzIyNTU2MC4yNi4xLjE2OTcyMjU5NTUuNDkuMC4w'/> */}
    {/* <Canvas />
    <Overlay /> */}
     <Customization />
    </>
  )
}

export default CusCall