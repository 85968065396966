import React, { useState } from "react";
  import Image1 from "../Assets/Clothes/Viewall.jpg";
  import Image2 from "../Assets/Clothes/Morgide.jpg";
  import Image3 from "../Assets/20.jpg";
  import Image4 from "../Assets/evian.jpg";
  import Nav2 from "./Nav2";
  import HomeButton from "./BackHome";
  import Clicked from "./ClickedColabo";
  import MobileNav from "../Components/MobileNav";
  import MobileNav2 from "../Components/MobileNav2";
  import { Link } from "react-router-dom";
  import first from '../Assets/24.jpg'
  import second from '../Assets/25.jpg'
  import video from '../Assets/data.mp4'
  import num1 from '../Assets/17.jpg'
  import num2 from '../Assets/18.jpg'
  import coco from '../Assets/them.jpg'
  import right from '../Assets/right.mp4'
  // import left from '../Assets/left2.mp4'
  import gurl from '../Assets/gurl.jpg'
  import middle from '../Assets/middle.jpg'
  import left3 from '../Assets/left3.mp4'
  import right2 from '../Assets/right2.mp4'
  import U from '../Assets/appstore.png'
  import Son from "../Assets/p1.jpg";
  import Slider from 'react-slick';
  import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3, // Adjust the number of slides to show at a time
    slidesToScroll: 1,
    arrows: true, // Set to false if you don't want arrow navigation
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // infinite: true,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const collaborationsData = [
    {
      id: 1,
      image: Image2,
      name: "MOJIRADE",
      video: video,
      pictureon: num1,
      pictureto: num2,
      // video2: left,
      video3: right,
      centerImage: gurl,
      content: "A collaboration with virtual influencer,  Mojirade, bridging the gap between reality and virtuality. sporting the Lder design from our FW23/24 collection.",
      },
    {
      id: 2,
      image: Image3,
      name: "Coco & Breezy",
      content: "A collaboration with celebrity Dj's Coco & Breezy for performance at Coachella weekend 2.",
      coco: coco,
      video2: left3,
      video3: right2,
        // centerImage: middle,
    },
    {
      id: 3,
      image: Image4,
      name: "Ivan Medrano",
      picture1: first,
      picture2: second,
      content: "A collaboration with digital designer and editorial 3D artist Ivan Medrano for the Independent Variable May 2023 featuring Astra Starr..",
    },
  ];

  const Collaborations = () => {
    const [selectedCollaboration, setSelectedCollaboration] = useState(collaborationsData);

    const handleClick = (collaboration) => {
      setSelectedCollaboration(collaboration);
    };

    const renderCollaborations = collaborationsData.map((collaboration) => (
      <div
        key={collaboration.id}
        className="flex items-center justify-center mx-2 cursor-pointer"
        onClick={() => handleClick(collaboration)}
      >
        <img
          className="w-20 h-20 rounded-full"
          style={{borderRadius: 200}}
          src={collaboration.image}
          alt={collaboration.name}
        />
        <p className="text-black text-center font-bold">{collaboration.name}</p>
      </div>
    ));

    return (
      <div className="bg-white h-screen bg-white  w-screen pt-20" 
      // style={{backgroundImage: `url(${Son})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}

      >

        <Nav2 />
        <MobileNav2 />

      {/* <div className="flex overflow-x-auto justify-center mt-10">
  <div 
    onClick={() => setSelectedCollaboration(collaborationsData)}
    className="flex flex-col items-center mx-2 cursor-pointer">
    <img className="w-28 h-28 rounded-full" style={{borderRadius: 200}} src={Image1} alt="View All" />
    <p className="text-black font-bold">View All</p>
  </div>
  <div className="flex flex-row space-x-2"> 
    {renderCollaborations}
  </div>
</div> */}
<div className="mt-10">
      <Slider {...settings}>
        <div 
          onClick={() => setSelectedCollaboration(collaborationsData)}
          className="flex items-center justify-center mx-2 cursor-pointer">
          <img className="w-20 h-20 rounded-full" src={Image1} alt="View All" />
          <p className="text-black text-center font-bold">View All</p>
        </div>
        {renderCollaborations}
      </Slider>
    </div>


        {selectedCollaboration && (
          <Clicked
            collaboration={selectedCollaboration}
            onClose={() => setSelectedCollaboration(null)}
          />
        )}
        {
        selectedCollaboration === collaborationsData && 
          collaborationsData.map((collaboration) => {
            return(
              <Clicked
                collaboration={collaboration}
                onClose={() => setSelectedCollaboration(null)}
              />
            )

          })
        }
          <div className=" mt-[30%]  w-full justify-center items-center">
            <h1 className=" text-center text-xl md:font-bold font-bold mb-6 mt-2 text-white">
          <img src={U} className="h-0 w-0" />    &copy; Nextberries 2023. All rights reserved <Link className='hover:text-blue-500' to={'/policy'}>Privacy Policy</Link>.
            </h1>
          </div>
      {/* </footer> */}
      </div>
    );
  };

  export default Collaborations;