/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";

import * as THREE from "three";
import { useCustomization } from "../contexts/Customization";
import { Canvas, useFrame } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Suspense } from "react";

/*
 ** CHAIR MATERIALS
 */

function TOP(props) {

    const Model = () => {
        const myMesh = React.useRef();

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime();
    myMesh.current.rotation.y = a;
  });
      const gltf = useLoader(GLTFLoader, "./jacket/n33.gltf");
      return (
        <>
        <mesh ref={myMesh}>

          <primitive object={gltf.scene} scale={0.4} />
        </mesh>
        </>
      );
    };

  return (
    <group {...props} dispose={null} >
     
                <Model />

    </group>
  );
}

export default TOP;

useGLTF.preload("./Skirt/N1.gltf");
