import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import Google from "../Components/Google";
import { Link } from "react-router-dom";
import firebase from "../firebaseconfig";
import V2 from "../Assets/me.png";
import { BiShoppingBag } from "react-icons/bi";
import { useCart } from "react-use-cart";


const Nav = ({title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isIconClosed, setIsIconClosed] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isShopAllOpen, setIsShopAllOpen] = useState(false);
  const [isUnisexOpen, setIsUnisexOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsIconClosed(!isIconClosed);
  };

  const handleShopAllDropdownClick = (e) => {
    e.preventDefault();
    setIsShopAllOpen(!isShopAllOpen);
    setIsUnisexOpen(false);
  };

  const handleUnisexDropdownClick = (e) => {
    e.preventDefault();
    setIsUnisexOpen(!isUnisexOpen);
    setIsShopAllOpen(false);
  };

  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup the event listener on unmount
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  const [color, setColor] = useState(false)
  const changeColor =() => {
      if(window.scrollY >= 100) {
          setColor(true)
      } else {
          setColor(false)
      }
  }
  const { isEmpty, totalItems } = useCart();

  window.addEventListener('scroll', changeColor)

  const style = {
    background: isScrolled ? "#000" : "#fff",
  };

  return (
    <div
      className={
        color
          ? "hidden lg:flex fixed z-50 bg-[rgba(0,0,0, .85)] opacity-90 w-[100%] p-10 "
          : "hidden lg:flex fixed z-50 p-10 w-[99%]"
      }
    >
   
      <div className="lg:hidden absolute left-3">
        <Link className="">
          <h1 className="text-white text-2xl font-bold">
            <Link to="/">NEXTBERRIES™</Link>
          </h1>
        </Link>
      </div>
      <nav
        className={`fixed top-5 md:top-0 md:left-0 md:right-0 left-72 z-50 flex justify-between items-center ${
          color ? "lg:bg-[#000000d9] text-white" : title === "me" ? "text-white" : "bg-transparent text-black"
          // color ? "lg:bg-[#000000d9]" : "bg-transparent"
        }  py-4 px-6`}
      >
        <div className="flex items-center"></div>
        <div className="absolute flex mb-2">
          <Link
            className="text-white z-50"
            onClick={toggleDropdown}
          >
            {isOpen ? (
              <AiOutlineClose className="h-5 w-5 z-50" />
            ) : (
              <AiOutlineMenu className="h-6 w-6 z-50" />
            )}
          </Link>
          <a href="https://pp.events/aaOmBZ9m">

          <img
            src={V2}
            alt={''}
            className="rounded-full ml-2 cursor-pointer h-6 w-6"
            />
            </a>
        </div>
        {isOpen && (
          <div 
          className="absolute md:left-5 md:top-[110%] mt-3"
          // className=" flex-col flex"
          >
            {/* <ul className="space-y-2"> */}
              <li className="mb-5 cursor-pointer">
                <a onClick={handleShopAllDropdownClick}>
                  Shop All
                </a>
                {isShopAllOpen && (
                  <div className="absolute md:left-0 md:top-[10%] mt-4">
                    <ul className="flex  space-x-4">
                      <li>
                        <a href="https://pp.events/aaOmBZ9m"> New </a>
                      </li>
                      <li>
                        <a href="https://pp.events/aaOmBZ9m">Eco </a>
                      </li>
                      <li>
                        <a href="https://pp.events/aaOmBZ9m">Tops </a>
                      </li>
                      <li>
                        <a href="https://pp.events/aaOmBZ9m">Dresses </a>
                      </li>
                      <li>
                        <a href="https://pp.events/aaOmBZ9m">Corsets </a>
                      </li>
                      <li>
                        <a href="https://pp.events/aaOmBZ9m">Pants </a>
                      </li>
                      <li>
                        <a href="https://pp.events/aaOmBZ9m">Skirts </a>
                      </li>
                      <li>
                        <a href="https://pp.events/aaOmBZ9m">Outerwear</a>
                      </li>
                      <li>
                        <a href="https://pp.events/aaOmBZ9m">Accessories </a>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
              <li>
                <a href="/" onClick={handleUnisexDropdownClick}>
                  Unisex
                </a>
                {isUnisexOpen && (
                  <div className="absolute md:left-0 md:top-[90%] mt-3">
                    <ul className="flex space-x-4">
                      <li>
                        <a href="https://pp.events/aaOmBZ9m"> New </a>
                      </li>
                      <li>
                        <a href="https://pp.events/aaOmBZ9m">All </a>
                      </li>
                      <li>
                        <a href="https://pp.events/aaOmBZ9m">Pants </a>
                      </li>
                      <li>
                        <a href="https://pp.events/aaOmBZ9m">Outerwear </a>
                      </li>
                      <li>
                        <a href="https://pp.events/aaOmBZ9m">Accessories </a>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
              {/* <li className="font-bold ">
                <a href="/">Home</a>
              </li>
              <li className="font-bold ">
                <a href="/collaborations">Collaborations</a>
              </li>
              <li className="font-bold ">
                <a href="/">Community</a>
              </li>
              <li className="font-bold ">
                <a href="/about">About</a>
              </li>
              <li className="font-bold ">
                <a href="/shop">Connect</a>
              </li> */}
            {/* </ul> */}
          </div>
        )}
        <div className="lg:flex hidden  items-center">
          <ul className="flex space-x-20">
         
          <li className="font-bold text-sm">
              <Link to="/">Home</Link>
            </li>
            <li className="font-bold text-sm">
              {/* <Link to="https://pp.events/aaOmBZ9m">Collaborations</Link> */}
              <Link to="/collaborations">Collaborations</Link>
            </li>
            <li className="font-bold text-sm">
              {/* <Link to="https://pp.events/aaOmBZ9m">Community</Link> */}
              <Link to="/News">News</Link>
            </li>
            <li className="font-bold text-sm">
              {/* <Link to="https://pp.events/aaOmBZ9m">About</Link> */}
              <Link to="/shop">About</Link>
              {/* <Link to="/about">About</Link> */}

            </li>
            <li className="font-bold text-sm">
              {/* <Link to="https://pp.events/aaOmBZ9m">About</Link> */}
              <Link to="/shop">Contact Us</Link>
              {/* <Link to="/about">About</Link> */}

            </li>
            {/* {

user ?

<li className="font-bold text-sm">
<Link to="/Order">Transactions</Link>
</li> 
: null
} */}
<li className="font-bold text-sm">
              <Link to="/shop">Shop Now</Link>

            </li>

            <li className="font-bold text-lg flex items-center">
            {/* <h1 className="font-bold fixed left-[80%] z-50 md:left-[92%] top-[90%]"> */}
          <Link to="/cart" className="">
            <BiShoppingBag
              size="24"
              color={title === "me" ? "#fff" : "#555"}
              className="rounded-full shadow-2xl drop-shadow-2xl shadow-gray-700 p-0"
            />
            {!isEmpty && (
              <span className="ml-6 absolute top-1 font-bold text-lg text-white rounded-full w-7 text-center bg-orange-600">
                {totalItems}
              </span>
            )}
            {/* <span style={{ marginLeft: !isEmpty ? "-13px" : 0 }}>&nbsp;</span> */}
          </Link>

            </li>
           
            {/* <li className="font-bold text-lg">
              <a href="/shop">Connect</a>
            </li> */}
            <li className="font-bold text-sm">
              <Google />
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
