import { Suspense, useState } from "react";
import reactLogo from "./Assets/react.svg";
import "./App.css";
import { Canvas } from "@react-three/fiber";
import Experience from "./component/Experience";
import Configurator from "./component/Configurator";
import { CustomizationProvider } from "./contexts/Customization";
import { Link } from "react-router-dom";
import CanvasLoader from "./Loader";
import EnvApp from "./New Env/EnvApp";
import Logo from "./New Env/icon.png";
import { BiArrowBack, BiArrowFromLeft } from "react-icons/bi";

function Customization() {

  const [Show, setShow] = useState(false)
  const [Show1, setShow1] = useState(1)
  const [Show2, setShow2] = useState(false)
 
  return (
    <CustomizationProvider>
      <div className="App" >
        <EnvApp  Show1={Show1} setShow1={setShow1}/>
        {/* <Canvas dpr={[1, 2]}>
          <Suspense fallback={<CanvasLoader />}>
            
          <color attach="background" args={["#656564"]} />
          <fog attach="fog" args={["#656564", 10, 20]} />
          <Experience />
          </Suspense>
        </Canvas> */}
        <>
        {
          Show1 === 1 ? null :

        <div 
        // onClick={() => setShow(!Show)}
         className="border-white shadow-2xl rounded-2xl absolute lg:left-10 left-3 z-50 border-2 absolute mt-4 top-[50%]">
          <Link
            // to={'/Customization'}
            // to={'/'}
                        onClick={() => setShow1(Show1 - 1)}
            className="block animate-pulse px-7 py-4 text-lg text-center text-white"
          >
            Prev
          </Link>
          </div>
        }
  {
          Show1 === 5 ? null :
        <div 
        // onClick={() => setShow(!Show)}
         className="border-white shadow-2xl rounded-2xl absolute lg:right-10 right-3 z-50 border-2 absolute mt-4 top-[50%]">
          <Link
            // to={'/Customization'}
                        onClick={() => setShow1(Show1 + 1)}
            // to={'/'}
            className="block animate-pulse px-7 py-4 text-lg text-center text-white"
          >
            Next
          </Link>
          </div>
  }


        <div 
        // onClick={() => setShow(!Show)}
         className="border-white shadow-2xl rounded-2xl absolute lg:left-10 left-3 z-50 border-2 absolute mt-4 top-0">
          <Link
            // to={'/Customization'}
            to={'/'}
            className="block animate-pulse px-7 py-4 text-lg text-center text-white"
          >
           <BiArrowBack />
          </Link>
          </div>
        <div onClick={() => setShow(!Show)} 
        className="border-white shadow-2xl rounded-2xl absolute lg:left-10 left-5 z-50 border-2 absolute mt-4 lg:bottom-5 bottom-5"
        >
          <Link
            // to={'/Customization'}
            // to={'/shop'}
            className="block animate-pulse px-7 py-4 text-lg text-center text-white"
          >
           {/* {Show === true ?'Hide Tools' : 'Show Tools'} */}
           NFT
          </Link>
          </div>

          <div className="absolute text-black left-14 bottom-20 lg:h-32 lg:w-32 h-20 w-20" >
            <img src={Logo} />

          </div>
          <div className="absolute text-black right-10 bottom-24" >{new Date().toLocaleDateString('en-US')}</div>
        <div
        className="border-white shadow-2xl bg-white hover:bg-transparent absolute right-5 z-50 border-2 absolute mt-4 lg:bottom-5 bottom-5 rounded-2xl"
        >
          <Link
            // to={'/Customization'}
            to={'/shop'}
            // onClick={() => setShow1(!Show1)}
            className="block text-gray-900 px-7 py-4 text-lg text-center"
          >
           Buy Now
          </Link>
          </div>
        {/* <div  
        className="bg-white h-96 absolute w-1 lg:left-10 right-10 top-32 flex justify-center rounded-full "
        >
          <div className="h-4 w-4 rounded-full bg-gray-200 absolute"  />
          
          </div> */}
        </>

          {
            Show === true ?
        <Configurator />
        : 
        null
          }
      </div>
    </CustomizationProvider>
  );
}

export default Customization;
