import React, { useState } from "react";
import { useCart } from "react-use-cart";
import { MdOutlineShoppingCart } from "react-icons/md";
import PaystackForm from "./PaystackForm";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Nav from "../component/Nav";
import Homes from "./Homes";
import MobileNav from "./MobileNav";
import { Link, useNavigate } from "react-router-dom";
import Son from "../Assets/p1.jpg";
import Son1 from "../Assets/forbes.png";
import Son2 from "../Assets/n1.png";
import Son3 from "../Assets/n2.png";
import Son4 from "../Assets/n3.png";
import Son5 from "../Assets/n4.png";


// SYKY - https://www.syky.com/syky-collective

// Vogue Business -  https://www.voguebusiness.com/technology/this-digital-fashion-incubator-hopes-to-launch-the-next-big-nameattached

// WWD - https://wwd.com/business-news/technology/exclusive-syky-collective-british-fashion-council-incubator-1235715089/

// Thisdaystyle 
// https://www.thisdaystyle.ng/nextberries-pioneering-the-future-of-fashion-in-lagos-nigeria-with-their-upcoming-collection/

// Deeds -https://www.deedsmag.com/stories/nextberries-pioneering-the-future-of-fashion-in-lagos-nigeria


// CoinDesk - https://www.coindesk.com/web3/2023/06/27/luxury-web3-fashion-platform-syky-launches-incubator-for-digital-designers/

// Yahoo! - https://ca.sports.yahoo.com/news/exclusive-syky-partners-british-fashion-100000332.html

// Forbes - https://www.forbes.com/sites/stephaniehirschmiller/2023/06/27/the-inaugural-lineup-of-digital-fashion-incubator-syky-includes-gucci-and-snapchat-alumni/?sh=fa00587315b7

// NFT NOW - https://nftnow.com/culture/now-runway-dior-unveils-b33-sneakers-equipped-with-nfc-chip/

// Bloomberg - https://www.bloomberg.com/press-releases/2023-06-27/the-future-of-fashion-is-digital-syky-unveils-10-designers-poised-to-disrupt-the-fashion-industry

// Business of Fashion - https://www.businessoffashion.com/news/technology/digital-fashion-platform-syky-launches-incubator-programme/


const firebaseConfig = {
  // Your Firebase config
  apiKey: "AIzaSyCRSVN6rIpkPQKLz77NFcES36Fpe665T9k",
  authDomain: "nextberries-cb5b9.firebaseapp.com",
  projectId: "nextberries-cb5b9",
  storageBucket: "nextberries-cb5b9.appspot.com",
  messagingSenderId: "44749615279",
  appId: "1:44749615279:web:0b3e110f248c8e8b3ae45a",
  measurementId: "G-9QZ27VRVHL",
};

firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();

const News = () => {

  const List = [
    {
        name: 'Sky',
        link: 'https://www.syky.com/syky-collective',
        image: 'https://www.syky.com/img/animated_logo/static.gif'
    },
    {
        name: 'Vogue Business',
        link: 'https://www.voguebusiness.com/technology/this-digital-fashion-incubator-hopes-to-launch-the-next-big-nameattached',
        image: 'https://www.voguebusiness.com/verso/static/vogue/assets/business/logo.svg'
    },
    {
        name: 'Direct Tithe',
        link: 'https://wwd.com/business-news/technology/exclusive-syky-collective-british-fashion-council-incubator-1235715089/',
        image: 'https://v.fastcdn.co/u/ed1a9b17/63282351-0-Womens-Wear-Daily-lo.svg'
    },
    {
        name: 'Direct Tithe',
        link: 'https://www.thisdaystyle.ng/nextberries-pioneering-the-future-of-fashion-in-lagos-nigeria-with-their-upcoming-collection/',
        image: 'https://www.thisdaystyle.ng/batman/2023/04/style-logo-v2.png'
    },
    {
        name: 'Direct Tithe',
        link: 'https://www.deedsmag.com/stories/nextberries-pioneering-the-future-of-fashion-in-lagos-nigeria',
        image: 'https://uploads-ssl.webflow.com/63304ed9e2391200fed8bf04/633410af5a9abd0b61782ad8_629c023fcf318c5bb1c99be5_deeds%20-%20Black.png'
    },
    {
        name: 'Direct Tithe',
        link: 'https://www.coindesk.com/web3/2023/06/27/luxury-web3-fashion-platform-syky-launches-incubator-for-digital-designers/',
        image: 'https://www.coindesk.com/pf/resources/images/logos/Coindesk_logo_396x75.svg?d=306'
    },
    {
        name: 'Direct Tithe',
        link: 'https://ca.sports.yahoo.com/news/exclusive-syky-partners-british-fashion-100000332.html',
        image: 'https://s.yimg.com/rz/p/yahoo_sports_en-CA_h_w_sportsv2.png'
    },
    {
        name: 'Direct Tithe',
        link: 'https://www.forbes.com/sites/stephaniehirschmiller/2023/06/27/the-inaugural-lineup-of-digital-fashion-incubator-syky-includes-gucci-and-snapchat-alumni/?sh=fa00587315b7',
        image: Son1
    },
    {
        name: 'Direct Tithe',
        link: 'https://nftnow.com/culture/now-runway-dior-unveils-b33-sneakers-equipped-with-nfc-chip/',
        image: 'https://nftnow.com/wp-content/themes/am/assets/build/img/nftnow-logo-black-horizontal.svg'
    },
    {
        name: 'Direct Tithe',
        link: 'https://www.bloomberg.com/press-releases/2023-06-27/the-future-of-fashion-is-digital-syky-unveils-10-designers-poised-to-disrupt-the-fashion-industry',
        image: 'https://assets.bwbx.io/s3/navi/images/logoBBGwht-4230a564d3.svg'
    },
    {
        name: 'Direct Tithe',
        link: 'https://www.businessoffashion.com/news/technology/digital-fashion-platform-syky-launches-incubator-programme/',
        image: 'https://www.businessoffashion.com/pf/resources/images/BofLogo.svg?d=241'
    },
   
]

  const {
    isEmpty,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();

  const [productQuantities, setProductQuantities] = useState({});

  const handlePaymentSuccess = (response, userData) => {
    console.log("Payment successful:", response);

    const payeeName = userData.name;
    const payeeLocation = userData.location;
    const payeeEmail = userData.email;
    const payeePostalCode = userData.postalCode;
    const payeePhoneNumber = userData.phoneNumber;

    const productsInCart = items.map((item) => ({
      id: item.id,
      name: item.title,
      quantity: productQuantities[item.id] || item.quantity,
    }));

    const data = {
      amount: cartTotal,
      createdAt: new Date(),
      productPaidFor: items.map((item) => item.title).join(", "),
      product_image: items.map((item) => item.product_image).join(", "),
      quantity: items.reduce((total, item) => total + item.quantity, 0),
      payeeName,
      payeeLocation,
      payeeEmail,
      payeePostalCode,
      payeePhoneNumber,
      productsInCart,
      status: 'Successful'

    };

    firestore
      .collection("Users")
      .doc(firebase.auth().currentUser.uid)
      .collection("Payments")
      .add(data)
      .then((docRef) => {
        console.log("Payment data saved with ID:", docRef.id);
      })
      .catch((error) => {
        console.error("Error saving payment data:", error);
      });

      // navigate()

      
    };
    
    // const navigate = <Link to={'/order'} />

  const handlePaymentClose = () => {
    console.log("Payment cancelled");
  };


  const handleOrderAttempts = (response, userData) => {
    const payeeName = userData.name;
    const payeeLocation = userData.location;
    const payeeEmail = userData.email;
    const payeePostalCode = userData.postalCode;
    const payeePhoneNumber = userData.phoneNumber;

    const productsInCart = items.map((item) => ({
      id: item.id,
      name: item.title,
      quantity: productQuantities[item.id] || item.quantity,
    }));

    const data = {
      amount: cartTotal,
      createdAt: new Date(),
      productPaidFor: items.map((item) => item.title).join(", "),
      product_image: items.map((item) => item.product_image).join(", "),
      quantity: items.reduce((total, item) => total + item.quantity, 0),
      payeeName,
      payeeLocation,
      payeeEmail,
      payeePostalCode,
      payeePhoneNumber,
      productsInCart,
      status: 'Attempt'
    };
    firestore
    .collection("Users")
    .doc(firebase.auth().currentUser.uid)
    .collection("OrderAttempt")
    .add(data)
    .then((docRef) => {
      console.log("Payment data saved with ID:", docRef.id);
    })
    .catch((error) => {
      console.error("Error saving payment data:", error);
    }); 
   };


  return (
    <div
        style={{backgroundImage: `url(${Son})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    // className="w-screen grid justify-center text-center pt-10 lg:pt-16 bg-[#3e3435]">
    className="w-screen text-[#000] grid justify-center text-center pt-10 lg:pt-16 bg-[#e3d2c0]">
    {/* className="w-screen grid justify-center text-center pt-10 lg:pt-16 bg-[#656564]"> */}
      <h1 className="text-[#000] text-6xl text-center m-5">
        NXB In News
      </h1>
      <div className="justify-content-center">
        {
          List.map((item, index) => (
            <>
            <Link to={item.link}>
      <div key={index} className="lg:px-96 px-32">

        <img src={item?.image} className="my-20 w-80" />
        {/* <img src={Son4} className="my-20" /> */}
        {/* <img src={Son3} className="" /> */}
        {/* <img src={Son2} className="my-20" />
        <img src={Son1} className="my-20" /> */}
        </div>
        </Link>
            </>
          ))

        }

       

<div className=" mt-[30%]  w-full justify-center items-center">
            <h1 className=" text-center text-xl md:font-bold font-bold mb-6 mt-2">
              &copy; Nextberries 2023. All rights reserved <Link className='hover:text-blue-500' to={'/policy'}>Privacy Policy</Link>.
            </h1>
          </div>
      </div>
      <Nav />
      <MobileNav />
         {/* <div className="bg-gray-900">
          <Homes />
        </div> */}
    </div>
  );
};

export default News;
