import React from "react";
import Hero from "./Components/Hero";
import Nav from "./component/Nav";
import { Route, Routes } from "react-router-dom";
import Shop from "./Components/Shop";
import Explore from "./Components/Explore";
import Cart from "./Components/Cart";
import ProductDetails from "./Components/ProductDetails";
import Collaborations from "./component/Collaborations";
import AboutUs from "./component/About";
import Order from "./Components/Order";
import DeliveryPolicy from "./Components/DeliveryPolicy";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import ProductPro from "./Components/ProductPro";
import CusCall from "./CusCall";
import New from "./New";
import News from "./Components/News";
import EnvCall from "./New Env/EnvCall";
import EnvApp from "./New Env/EnvApp";
import Footer2 from "./Components/Footer2";
// import Homes from "./components/Homes";

const Home = () => {
  return (
    <div style={{backgroundColor: '#fff'}}>
      {/* <Nav /> */}
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/News" element={<News />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/pro" element={<ProductPro />} />
        <Route path="/explore" element={<Explore />} />
        <Route
          path="/product-details/:productId"
          element={<ProductDetails />}
        />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/collaborations" element={<Collaborations />} />
        <Route path="/she" element={<Explore />} />
        <Route path="/order" element={<Order />} />
        <Route path="/delivery" element={<DeliveryPolicy />} />
        <Route path="/policy" element={<PrivacyPolicy />} />
        {/* <Route path="/Customization" element={<EnvApp />} /> */}
        <Route path="/Customization" element={<CusCall />} />
        <Route path="/Buy" element={<New />} />
      </Routes>

    </div>
  );
};

export default Home;
