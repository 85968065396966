import React, { useState, useEffect } from "react";
import { useCart } from "react-use-cart";
import { BsCartPlus } from "react-icons/bs";
import { AiOutlineArrowRight } from "react-icons/ai";
import firebase from "firebase/compat/app";
import firebaseConfig from "../firebaseconfig";
import "firebase/compat/firestore";
import { Link, useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Nav from "../component/Nav";
import Homes from "./Homes";
import Son from "../Assets/seb.jpg";
import MobileNav from "./MobileNav";

const ProductDetails = () => {
  const { productId } = useParams();
  const [productData, setProductData] = useState(null);
  const { addItem } = useCart();

  useEffect(() => {
    async function getProductData() {
      // Initialize Firebase
      const firebaseConfig = {
        // Your Firebase configuration
        apiKey: "AIzaSyCRSVN6rIpkPQKLz77NFcES36Fpe665T9k",
        authDomain: "nextberries-cb5b9.firebaseapp.com",
        projectId: "nextberries-cb5b9",
        storageBucket: "nextberries-cb5b9.appspot.com",
        messagingSenderId: "44749615279",
        appId: "1:44749615279:web:0b3e110f248c8e8b3ae45a",
        measurementId: "G-9QZ27VRVHL",
      };

      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      }

      const db = firebase.firestore();
      const docRef = db.collection("Products").doc(productId);
      const doc = await docRef.get();

      if (doc.exists) {
        const data = doc.data();
        const colors =
          typeof data.color === "string" ? data.color.split(",") : [];
        setProductData({ id: doc.id, ...data, colors });
      } else {
        console.log("No such document!");
        setProductData(null);
      }
    }

    getProductData();
  }, [productId]);

  if (!productData) {
    return <div>Loading...</div>;
  }

  return (
    <div 
    // style={{backgroundImage: `url(${Son})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    className="py-5 bg-[#ffffff] w-[100%] text-black pt-20">
      <div className="justify-content-center grid lg:grid-cols-2 mt-5">
        <div xs={10} md={7} lg={5} className="p-0 w-fit">
          {productData.product_image && productData.product_image.length > 0 ? (
            <Carousel showArrows={true} showThumbs={true}>
              {productData.product_image.map((Cloth, index) => (
                <div
                  key={index}
                  className="lg:w-fit  w-[90%] ml-[2%] lg:ml-[10%] border-2 border-white p-2 rounded-lg text-center"
                >
                  <img src={Cloth} alt={`Cloth ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          ) : (
            <div>No images available</div>
          )}
          {/* <div
          > 
            <AiOutlineArrowRight color="green" size={30} className="md:hidden my-7 ml-[45%]"/>
          </div> */}
        </div>
      <div xs={10} md={7} lg={7} className="product-details lg:w-[30%] w-[80%] p-5 gap-1 md:grid lg:right-20 lg:absolute">
          <div>
            <img className="w-48 drop-shadow-2xl shadow-2xl mb-3 rounded-lg" src={"https://firebasestorage.googleapis.com/v0/b/nextberries-cb5b9.appspot.com/o/barcode%2FIMG_1446.jpg?alt=media&token=f2f60197-cdb1-4c3b-98e6-d480560b1c6e"} alt={"qr_code"} />
              {/* <p className="font-bankGothic">scan me</p> */}
          </div>
        <div>
          <h1 className="text-2xl">{productData.title}</h1>
          <button
            onClick={() => addItem(productData)}
            className="m-2 flex font-bold cursor-pointer text-center justify-center items-center hover:text-gray-50 hover:bg-gray-600 drop-shadow-2xl shadow-2xl py-3 px-6 bg-gray-50 text-black duration-500 rounded-lg"
            // className="bg-dark-primary mt-2 mb-2 p-1 rounded-2xl bg-white  hover:bg-green-600 hover:text-white duration-500  text-green-600"
            style={{ borderRadius: "20", border: 0 }}
          >
            <BsCartPlus size="1.8rem" className="ml-0" />
            Add to shopping bag
          </button>
          <br />
          <div className="text-black p-3 mb-2 bg-slate-50 ">
          Price $: {productData.price}
          {/* Price ₦: {productData.price} */}
          </div>
          <br />
          {/* <strike className="text-red-300 font-extralight mt-3">
            $. {productData.old_price}
          </strike> */}
          <b className="h5 ">Rated 5.0 ⭐⭐⭐⭐⭐</b>
          <br />


          <b className="h5">Description:</b>
          <p
            className=" mb-5 text-xl"
            style={{ opacity: "0.8", fontWeight: "400" }}
          >
            {productData.descriprion}
          </p>
          {/* <p className="mt-3 mb-5 h5" style={{ opacity: "0.8", fontWeight: "400" }}>
            Colors:
            {productData.color.map((color, index) => (
              <span key={index}>
                {color.trim()}
                {index !== productData.product_color.length - 1 ? ", " : ""}
              </span>
            ))}
            {productData.product_color}
          </p> */}
          <div
          // to={'/Customization'}
            onClick={() => alert('Coming Soon')}
            className="m-2 flex font-bold cursor-pointer text-center justify-center items-center hover:text-gray-50 hover:bg-gray-600 drop-shadow-2xl shadow-2xl py-3 px-0  bg-gray-50 text-black duration-500 rounded-lg"
            // className="bg-dark-primary mt-10 mb-10 p-2 rounded-2xl bg-white  hover:bg-green-600 hover:text-white duration-500  text-green-600"
            style={{ borderRadius: "20", border: 0 }}
          >
            Customize
          </div>
          {/* <p className="mt-3 h5" style={{ opacity: "0.8", fontWeight: "400" }}>
            ${productData.shipping_fee}
          </p> */}
         </div>
      </div>
      </div>
      <Nav />
      <MobileNav title={"me"}/>
      <Homes />
    </div>
  );
};

export default ProductDetails;
