import React, { useEffect, useState } from "react";
import firebase from "../firebaseconfig";
import { MdOutlineShoppingCart } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useFlutterwave, FlutterWaveButton } from "flutterwave-react-v3";
import Son from "../Assets/seb.jpg";
import { usePaystackPayment } from "react-paystack";

const PaymentForm = ({ amount, email, onSuccess, onClose, handleOrderAttempts }) => {
  const [userData, setUserData] = useState({
    name: "",
    email,
    phoneNumber: "",
    postalCode: "",
    location: "",
  });

  const config = {
    reference: new Date().getTime().toString(),
    email: userData?.email,
    amount: amount * 100 * 1000, // Paystack accepts amount in kobo, so multiply by 100
    // publicKey: "sk_live_eec79e1a9ebbd50087502e8ac52dc19a9da1980c", // Replace with your Paystack public key
    publicKey: "pk_live_1c64818f3b0f7b300fb0ec33ca60c5a9f598ac09", // Replace with your Paystack public key
    // publicKey: "pk_test_a6299b3c7d13aff9b8fe0ffa7a5dc16a6442d86f", // Replace with your Paystack public key
  };

  // const config = {
  //   public_key: 'FLWPUBK-f3745c8499f9e76b41eb308c4994e59a-X', // Replace with your Flutterwave public key
  //   tx_ref: new Date().getTime().toString(),
  //   amount: amount,
  //   currency: 'USD',
  //   // payment_type: 'card',
  //   meta: {
  //     consumer_id: userData.email,
  //   },
  //   // payment_plan: '',
  //   payment_options: 'card, mobilemoney, ussd',
  //   customer: {
  //     email: userData.email,
  //     phone_number: userData.phoneNumber,
  //     name: userData.name,
  //   },
  //   customizations: {
  //     title: 'NXB_PAY',
  //     description: 'Pay for something',
  //     logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzAQIHnLrLEAgb4UuoKNqx3BpqHu9fd7_tkof7foHahw&s'
  //   },
  // };

  const onSuccessCallback = (response) => {
    // Handle successful payment
    onSuccess(response, userData);
  };

  const onCloseCallback = (response) => {
    // Handle payment cancellation
    onClose();
    handleOrderAttempts(response, userData);
  };

  const initializePayment = usePaystackPayment(config);

  useEffect(() => {
    // Check if the user is already signed in
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const { displayName, photoURL } = user;
        const updatedUserData = { ...userData, name: displayName };
        setUserData(updatedUserData);
      }
    });

    return () => unsubscribe(); // Cleanup the event listener on unmount
  }, []);

  const handlePayment = () => {
    const user = firebase.auth().currentUser;

    if (user) {
      initializePayment(onSuccessCallback, onCloseCallback);
    } else {
      // User is not signed in, show Firebase Google popup auth window
      const provider = new firebase.auth.GoogleAuthProvider();

      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          const { displayName, photoURL } = result.user;
          const updatedUserData = { ...userData, name: displayName };
          setUserData(updatedUserData);
          initializePayment(onSuccessCallback, onCloseCallback);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const navigate = useNavigate();

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="">
      {!nav ? (
        <div
          style={{
            backgroundImage: `url(${Son})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className={
            !nav
              ? "bg-black h-screen z-50 p-0 md:p-10 fixed top-0 right-0 left-0 text-white"
              : "md:hidden "
          }
        >
          <div className=" md:flex gap-5 justify-center items-center p-0">
            <div className="mt-20 md:flex gap-5">
              <div>
                <label>Full Name</label>
                <br />
                <input
                  className="bg-[#333] p-2"
                  type="text"
                  name="name"
                  value={userData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Email Address</label>
                <br />
                <input
                  className="bg-[#333] p-2"
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Phone Number</label>
                <br />
                <input
                  className="bg-[#333] p-2"
                  type="text"
                  name="phoneNumber"
                  value={userData.phoneNumber}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Delivery Address</label>
                <br />
                <input
                  className="bg-[#333] p-2"
                  type="text"
                  name="location"
                  value={userData.location}
                  onChange={handleChange}
                />
                <br />
                <Link
                  to={"/delivery"}
                  className="hover:text-gray-600 text-green-600"
                >
                  Read Delivery Policy
                </Link>
              </div>
              <div>
                <label> Delivery Note </label>
                <br />
                <input
                  className="bg-[#333] p-2"
                  type="text"
                  name="postalCode"
                  value={userData.postalCode}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <br />
          <div className="">
            <button
              onClick={handlePayment}
              className="rounded-lg md:w-[53%] w-[80%] m-5 h-14 bg-white"
            >
              <div className="flex items-center justify-center ">
                <img
                  style={{ width: 24, height: 24 }}
                  src="https://static-00.iconduck.com/assets.00/paystack-icon-512x504-w7v8l6as.png"
                  alt="Flutterwave"
                />
                <p className="text-black ml-2 text-lg">Pay With PayStack</p>
              </div>
            </button>
            <br />
            <button
              onClick={handleNav}
              className=" mt-5 w-[53%]  md:mt-0 rounded-lg"
            >
              {/* Add PayPal or other payment options here */}
            </button>
            <br />
            <button
              onClick={handleNav}
              className="border-2 mt-5 w-[53%]  md:mt-0 border-white rounded-lg h-14 bg-gray-700"
            >
              cancel
            </button>
          </div>
        </div>
      ) : (
        <button
          className="m-2 hover:text-green-500 hover:bg-white text-center items-center flex font-bold md:py-10 py-7 px-7 drop-shadow-2xl shadow-2xl bg-green-500 text-white rounded-lg"
          onClick={handleNav}
        >
          <MdOutlineShoppingCart size="1.7rem" className="mr-2" />
          Pay Now
        </button>
      )}
    </div>
  );
};

export default PaymentForm;
