import React, { useState } from "react";

const Clicked = ({ collaboration, onClose }) => {
  const hasVideo = collaboration.video !== undefined;
  const [mos, setMos] = useState(false)

  return (
    <>
      <div className="flex flex-col bg-white md:flex-row p-5 md:p-20">
        <div className="w-full md:w-1/2">
          <img
            src={collaboration.image}
            alt={collaboration.name}
            className="w-full h-auto"
          />
        </div>
        <div className="w-full md:w-1/2 p-5 md:p-10 mt-4 md:mt-0">
          <h1 className="text-2xl md:text-4xl font-bold mb-4 text-black">
            Nextberries™ x {collaboration.name}
          </h1>
          <p className="text-lg md:text-3xl text-black text-left">
            {collaboration.content}
          </p>
          <button
            className="text-white bg-gray-800 px-4 py-2 mt-4 rounded"
            onClick={onClose}
          >
            Close
          </button>

          <div 
          onMouseEnter={()=>setMos(true)} onMouseLeave={()=>setMos(false)}
          className="lg:flex grid items-center justify-center space-x-4 mt-4 lg:ml-10">
            {collaboration.video3 && (
              <video
                src={collaboration.video3}
                autoPlay={mos}
                loop
                muted
                alt={collaboration.name}
                className="lg:max-w-xs"
              />
            )}
            {collaboration.centerImage && (
              <img src={collaboration.centerImage} alt={collaboration.name} className="max-w-xs  lg:mt-0 mt-10 rounded border-2 border-gray-300" />
            )}
            {collaboration.video2 && (
              <video
                src={collaboration.video2}
                autoPlay={mos}
                loop
                muted
                alt={collaboration.name}
                className="lg:max-w-xs"
              />
            )}
          </div>
        </div>
      </div>

      <div className="bg-black">
        {hasVideo && (
          <video src={collaboration.video} autoPlay={mos} loop muted />
        )}
        {collaboration.picture1 && (
          <img src={collaboration.picture1} alt={collaboration.name} className="w-full" />
        )}
        {collaboration.picture2 && (
          <img src={collaboration.picture2} alt={collaboration.name} className="w-full" />
        )}
        {collaboration.pictureon && (
          <img src={collaboration.pictureon} alt={collaboration.name} className="w-full" />
        )}
        {collaboration.pictureto && (
          <img src={collaboration.pictureto} alt={collaboration.name} className="w-full" />
        )}
        {collaboration.coco && (
          <img src={collaboration.coco} alt={collaboration.name} className="w-full" />
        )}
      </div>
    </>
  );
};

export default Clicked;